<template>
  <div>
    <CRow>
      <CCol>
  <CCard>
   
  <CCardBody>
    <div class="clearfix">
      <button type="button" class="btn btn-primary float-xl-right" @click="navToNewGroup">New Group </button>
    </div>

    <CDataTable :items="items"  
    :fields="fields"
      striped
      clickable-rows
      column-filter
       table-filter
        items-per-page-select
      :items-per-page="20"
       sorter
       ref="vuetable"
      pagination
        :responsive="false">
       
      <template #index="data">
       <td>
         {{data.index +1}}
         </td>
     </template>

      <template #createdTime="{item}">
        <td class='font-weight-bold'
          v-if="item.createdTime"
        >{{ item.createdTime |dateParse()| dateFormat("MMM D, YYYY hh:mm A") }}</td>
        <td v-else>--</td>
      </template>
     <template #groupName="{item}">
        <td class='font-weight-bold'
          v-if="item.groupName"
        >{{ item.groupName }}</td>
        <td v-else>--</td>
      </template>
     
       <template #show_details="{item}">
        <td class="py-2">
        <CDropdown
                  togglerText="Actions"
                  color="info"
                >
                  <CDropdownItem :to="{name: 'Group', params: {groupId: item.groupId, mode: 'view'}}">View </CDropdownItem>
           
                 <CDropdownItem :to="{name: 'Sub Groups', params: {groupId: item.groupId, mode: 'view'}}">Sub Groups</CDropdownItem>
                   <CDropdownItem :to="{name: 'Work Permits', params: {groupId: item.groupId, mode: 'view'}}">Work Permit </CDropdownItem>
                  <div  v-if="item.groupId==1"> 
                   <CDropdownItem :to="{name: 'QmsJobs', params: { mode: 'view'}}">QMS Jobs </CDropdownItem>
                  </div>
                  <div  v-if="item.groupId==2"> 
                  <CDropdownItem 
                   
                  :to="{name: 'Locations', params: {groupId: item.groupId, mode: 'view'}}">Locations</CDropdownItem>
                 </div>
                <ConfirmationModal
                      :parent="$refs.modalArea"
                      :description="'Are you sure to Delete'"
                      @on:ok="deleteGroup(item.groupId)"
                    ></ConfirmationModal>

                </CDropdown>

        
        </td>
      </template>
      </CDataTable>
  </CCardBody>
  <div ref="modalArea"></div>

 </CCard>
 
      </CCol>
      </CRow>
 </div>
</template>

<script>
const fields = [
  'index',
 
  { key: "groupName", _classes: 'font-weight-bold', _style: "min-width:200px" },
  
  { key: "createdTime",label: "Created Date & Time", _style: "min-width:200px" },
   { key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
  
];
import ConfirmationModal from "../../containers/ConfirmationModal";
export default {
  name: "Groups",
  components: {
    ConfirmationModal,
  },
  data() {
    return {
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
    };
  },
  methods: {
    getDepartments() {
      this.apiGetSecure(process.env.VUE_APP_API_HOST + "/emapp/web/secure/get/groups")
        .then((response) => response.json())
        .then((data) => (this.items = data));
    },
   
    deleteGroup(groupId) {
      console.log("delete called with", this.groupId);
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/delete/group/" +
         groupId
      )
        .then((response) => response.json())
        .then((data) => {
          this.getDepartments();
             this.$nextTick(() => {
             this.$refs.vuetable.refresh();
           });
           window.location.reload();
        });
    },
    navToNewGroup(){
       this.$router.push({ name: "NewGroup", params: { mode: "new" } });
    }
  },
  mounted() {
    this.getDepartments();
  },
};
</script>